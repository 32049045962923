.productCard {
  font-family: var(--font-primary);
  font-weight: 400;
}

.sizeNumber {
  padding: 2% 5%;
  cursor: pointer;
  color: var(--color-black);
  transition: unset;
  position: relative;
}

.sizeNumber:not(.sizeNotAvailable):hover {
  color: var(--color-black);
  text-decoration: underline;
}

.sizeNotAvailable {
  color: #919191;
  background: url('/assets-new/del-line-2.svg') center center no-repeat;
}

.marquee {
  display: flex;
  position: relative;
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-20% + var(--offset));
  --move-final: calc(-40% + var(--offset));
  align-self: end;
  background: #f6f6f7;
  color: #115b6e;
  font-size: 0.75em;
  grid-column: 1;
  grid-row: 1;
  padding: 5px 0;
  text-transform: uppercase;
}

.marqueeInner {
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 5s linear infinite;
  animation-play-state: running;
}

.marquee span {
  padding: 0 20px;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

.gridPhoto {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

body[data-domain='nnormal'] .gridPhoto {
  position: relative;
}

.oldShooting {
  box-shadow: -1px 0px #d1cfd2;
}

body[data-domain='nnormal'] .oldShooting {
  box-shadow: none;
}

.gridImages,
.gridVideo {
  display: grid;
  grid-column: 1;
  grid-row: 1;
  overflow: hidden;
  padding-bottom: 150%;
  background: #e8e7ea;
}
body[data-domain='camperlab'] .gridImages {
  background: none;
}

.gridImages[data-square='true'] picture img,
.gridVideo[data-square='true'] picture {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: bottom center;
}

.gridImages[data-square='true'] {
  padding-bottom: 100%;
}

.gridImages > picture,
.gridVideo {
  position: absolute;
  overflow: hidden;
}

.gridVideo {
  max-width: 100%;
  padding-bottom: 0px;
}

.sizes {
  align-self: end;
  display: grid;
  font-size: 0.875em;
  grid-gap: 8px 18px;
  grid-template-columns: repeat(auto-fill, minmax(20px, 1fr));
  margin: 24px;
  grid-column: 1;
  grid-row: 1;
  z-index: -1;
  visibility: hidden;
}

.sizesVisible {
  visibility: visible;
  z-index: 2;
}

.sizesWrapper {
  align-self: end;
  z-index: -1;
  visibility: hidden;
  display: block;
}

.sizesWrapper > .sizes {
  margin-top: 5px;
  margin-bottom: 5px;
}

.sizesWrapperVisible {
  visibility: visible;
  z-index: 2;
}

.addToCart {
  margin-left: 24px;
  text-transform: uppercase;
}

.marqueeContainer {
  align-self: end;
  background: #f6f6f7;
  color: #115b6e;
  font-size: 0.75em;
  grid-column: 1;
  grid-row: 1;
  padding: 5px 0;
  pointer-events: none;
  text-align: center;
  text-transform: uppercase;
}

.tierImg {
  grid-column: 1;
  grid-row: 1;
  height: auto;
  min-height: auto;
  width: 100%;
  vertical-align: top;
}

.imageHover {
  grid-column: 1;
  grid-row: 1;
  height: auto;
  min-height: auto;
  width: 100%;
  visibility: hidden;
}

.visible {
  /* z-index: 2; */
  visibility: visible;
}

.gridContent {
  margin: 4px 8px 0px 16px;
  flex-direction: column;
  background-color: transparent;
  position: relative;
}

body[data-domain='nnormal'] .gridContent {
  margin: 1rem 0.5rem;
}

.exclusive {
  position: absolute;
  width: 100%;
  font-weight: 300;
  bottom: 0;
  margin: 0;
  background-color: var(--color-green-nnormal);
  padding: 0.5rem;
  font-family: var(--font-secondary);
  text-transform: lowercase;
  line-height: normal;
}

.name {
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 0px;
  font-size: 0.875em;
}

body[data-domain='nnormal'] .name {
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 0.875em;
}
body[data-domain='camperlab'] .name {
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 120%;
}

.sku {
  color: #919191;
  font-size: 0.625em;
}

.price {
  color: var(--color-black);
  font-size: 0.875em;
  margin-bottom: 0px;
}

body[data-domain='camperlab'] .price {
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 120%;
  color: #000;
}

.installments {
  color: var(--color-black);
  font-size: var(--description-size);
  margin-bottom: 0px;
}

.discountText {
  color: var(--color-disabled-primary);
  margin-bottom: 0px;
  font-size: 0.75em;
}

body[data-domain='camperlab'] .discountText {
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 120%;
  color: #a3a3a3;
  gap: 6px;
  margin-left: 6px;
}

.discount {
  text-transform: uppercase;
  margin-left: 5px;
}

body[data-domain='camperlab'] .discount {
  margin-left: 0;
}

.itemInfo {
  color: var(--color-black);
  font-size: 0.5em;
  margin-bottom: 0px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 40px 16px;
}

.gridTierPhoto {
  position: relative;
}

.tierWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.tierWrapper > *:first-child {
  height: 100%;
}

.tierWrapper > div > a {
  height: 100%;
}

.customizeContainer {
  position: absolute;
  top: 0px;
  z-index: 1;
  left: 0px;
  color: var(--color-black);
}

.customizeContainer > p {
  font-size: 0.8em;
  padding: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.notInViewImg {
  grid-column: 1;
  grid-row: 1;
  height: auto;
  min-height: auto;
  width: 100%;
  vertical-align: top;
  filter: none;
  opacity: 1;
  transition: opacity 1s ease-in;
  box-shadow: inset -6px 0 20px 4px hsl(0deg 0% 81% / 34%);
  background: #e8e8ea;
}

.noGridDisplay {
  display: flex;
}

.productSubtitle {
  margin-top: 0px;
  color: var(--color-border-panel);
  font-size: 0.6em;
  margin-bottom: 10px;
  font-family: 'ModerneRegular';
}

body[data-domain='nnormal'] .productSubtitle {
  font-family: var(--font-primary);
  color: var(--color-black);
  font-size: 0.875rem;
  margin: 7px 0;
}
body[data-domain='camperlab'] .productSubtitle {
  font-family: var(--font-primary);
  color: #000;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 120%;
}

.preOrderContainer {
  background-color: var(--color-border-grey);
  font-weight: 400;
}

.preOrderContainer > p {
  padding: 5px 10px;
}

.recalcPrice {
  min-width: 30%;
}

.promoLabel {
  margin-bottom: 10px;
  color: var(--color-success);
  font-size: var(--description-size);
  text-align: center;
}

.voucherApplied {
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 12px;
  color: var(--color-success);
}

body[data-domain='camperlab'] .voucherApplied {
  color: #a3a3a3;
  font-weight: 700;
  line-height: 120%;
}

.pricePerSize {
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 12px;
  color: var(--color-border-panel);
}

.addToCartNotification {
  padding: 16px;
  margin-bottom: 0px;
}

.addToCartNotification :global .ant-notification-notice-close {
  top: 12px;
}

.addToCartNotification :global .ant-notification-notice-message {
  font-size: 0.75em;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
  line-height: 17px;
}

.bagAddedItemUl {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.bagAddedItem {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid #dfdfdf;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 16px 0;
  margin-top: 8px;
}

.bagAddedItem > img {
  width: 100px;
}

.bagAddedItemContent {
  list-style: none;
  margin-left: 16px;
  padding-left: 0px;
}

.bagAddedTitle {
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 0px;
}

body[data-domain='nnormal'] .bagAddedTitle {
  font-size: 0.875em;
  font-family: var(--font-secondary);
  font-weight: 600;
  margin-top: 8px;
}

.bagAddedCode {
  color: #919191;
  font-size: 0.5rem;
  margin-bottom: 0px;
}

body[data-domain='nnormal'] .bagAddedCode {
  color: var(--color-black);
  font-size: 0.75rem;
}

.bagAddedProperty {
  font-size: 0.75em;
  margin-bottom: 0px;
}

.bagAddedSize {
  font-size: 0.75em;
  margin-top: 8px;
  margin-bottom: 0px;
}

body[data-domain='nnormal'] .bagAddedSize {
  margin-top: 0;
}

.bagAddedPrice {
  margin-top: 20px;
  margin-bottom: 0px;
}

body[data-domain='nnormal'] .bagAddedPrice {
  margin-top: 0;
  font-size: 0.75rem;
}

.btnGoPurchase {
  padding: 10px 20px;
  height: auto;
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
}

.btnGoPurchase p {
  margin-bottom: 0px;
}

.spin {
  display: none;
  left: 50%;
  transform: translate(-50%, 4px);
}

body[data-domain='nnormal'] .btnGoPurchase {
  font-family: var(--font-secondary);
}

.btnGoPurchase:hover,
.btnGoPurchase:active,
.btnGoPurchase:focus {
  border-color: var(--color-black);
  background-color: var(--color-white);
  color: var(--color-black);
  box-shadow: none;
}

.pricePerSize {
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 12px;
  color: var(--color-border-panel);
}

.productSku {
  font-size: var(--description-size);
}

.discountPriceText {
  margin-bottom: 0;
  line-height: 1;
  color: var(--color-disabled-primary);
  font-size: 0.75em;
}

.discountPercent {
  color: var(--color-disabled-primary);
}

.discountByVoucher {
  color: var(--color-success);
}
body[data-domain='camperlab'] .discountByVoucher {
  color: #a3a3a3;
}

.discountPricePrevious {
  margin-right: 5px;
}

.smallText {
  color: var(--color-disabled-primary);
  font-size: var(--title-size);
  margin-top: 8px;
  text-align: center;
  margin-bottom: 0px;
}

.priceSpan {
  font-weight: 300;
  font-size: inherit;
  margin: 16px 0 0 0;
}

.voucherPrice {
  font-size: 10px;
  color: var(--voucher-cta);
  font-weight: 550;
  /*   word-wrap: break-word;
  max-width: calc(100% - 16px); */
  /* hay que restar 16px porque el div padre tiene 16px de margen */
}

.voucherPrice :global a {
  text-decoration: underline;
  font-weight: 600;
}

.sizeLabel {
  position: absolute;
  bottom: 0;
}

.bell {
  position: absolute;
  right: -6px;
  top: -2px;
  scale: 0.7;
}

.withBell {
  color: var(--color-disabled-primary);
}

.addToBagRecommended {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--title-size);
  width: 80%;
}

.addToBagRecommended:hover {
  background-color: var(--color-black);
  color: var(--color-white);
}

.sizesMenu {
  padding: 16px 20px;
}
.sizesMenu :global .ant-list-item {
  text-align: center;
  margin-bottom: 0px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.sizesDropdown {
  z-index: 490;
}
.sizesDropdown > div:first-child {
  height: 16px !important;
  width: 16px !important;
}

.sizeSpinner {
  display: flex;
  width: 100%;
  justify-content: center;
}

.reducedSize {
  margin: 12px 24px;
}

.manySizes {
  grid-gap: 6px 8px;
}
