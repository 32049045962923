.sectionRowGrid {
  flex-flow: nowrap;
  overflow: hidden;
  overflow-x: auto;
}

.title {
  font-size: 1em;
  margin-left: 24px;
  margin-bottom: 24px;
  margin-top: 80px;
  font-weight: 400;
}
body[data-domain="nnormal"] .title:empty {
  display: none;
}